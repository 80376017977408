import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {Component} from '@angular/core';
import {GenericResponse} from "./models/GenericResponse.model";
import {Setting} from "./models/Setting.model";
import {SettingsService} from "./services/settings.service";
import {TranslateService} from "@ngx-translate/core";
import {User} from "./models/User";
import {UserService} from "./services/user.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    public token: string;
    public shopUrl: string;
    public maintenance: boolean = false;
    public analyticsAccepted: boolean = false;
    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        translate.addLangs(['de', 'en']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/de|en/) ? browserLang : 'en');
        this.authService.loggedInEvent.subscribe(() => {
            this.getWpAuth();
            this.getShopUrl();
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if(params.fID != undefined){
                localStorage.setItem('shopSelectedTemplate', params.fID);
                this.router.navigate(['/child-list']);
            }else{
                localStorage.removeItem('shopSelectedTemplate');
            }
        });
        this.maintenance = this.settingsService.maintenanceMode;
        this.settingsService.maintenanceEvent.subscribe(() => {
            this.maintenance = this.settingsService.maintenanceMode;
        });
        let cookies = document.cookie.split(';');
        let scriptAdded = false;
        for(let i = 0; i < cookies.length; i++){
            let name = cookies[i].trim().split('=')[0];
            if(!scriptAdded && name.startsWith('_ga') && name.includes('62ZPVZC9VZ')){
                document.getElementById('googleScriptContainer').innerHTML = '';
                document.getElementById('googleScriptContainer').innerHTML = `<!-- Global site tag (gtag.js) - Google Analytics -->
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-7CGD9NFYC5"></script>
                    <script>
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', 'G-7CGD9NFYC5');
                    </script>`;
                /*
                document.getElementById('googleScriptContainer').innerHTML = `<!-- Google Analytics -->
                    <script>
                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                                        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

                                ga('create', 'UA-XXXXX-Y', 'auto');
                                ga('send', 'pageview');
                                </script>
                    <!-- End Google Analytics -->`;
                 */
                scriptAdded = true;
            }
        }
    }

    public langChange(event): void{
        try{
            this.translate.use(event.target.value);
        }catch (e){
            const browserLang = this.translate.getBrowserLang();
            this.translate.use(browserLang.match(/de|en/) ? browserLang : 'en');
        }
    }

    public removeToast(): void{
        document.getElementById('porttoolioToast').setAttribute('data-status', 'hidden');
    }

    public getWpAuth(): void{
        this.userService.getSelf().toPromise()
            .catch(err => {
                this.token = '';
            })
            .then((res: GenericResponse<User>) => {
                this.token = encodeURIComponent(res.body.email + ';' + res.token);
            });
    }

    public getShopUrl(): void{
        this.settingsService.get(SettingsService.SETTING_WORDPRESS_SHOP_URL).toPromise()
            .catch(err => {
                this.shopUrl = '';
            })
            .then((res: GenericResponse<Setting>) => {
                this.shopUrl = res.body.value;
            });
    }

    public removeDeviceSizeWarn(): void{
        if(document.getElementById('deviceSizeWarn') != undefined){
            document.getElementById('deviceSizeWarn').remove();
        }
    }
}
