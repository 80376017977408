import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-delete-profile-confirm',
    templateUrl: './delete-profile-confirm.component.html',
    styleUrls: ['./delete-profile-confirm.component.scss'],
})
export class DeleteProfileConfirmComponent implements OnInit {

    public confirmSequenceInput: string = '';
    public confirmSequence: string = '';
    private randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    constructor(
        public modalController: ModalController
    ) { }

    // tslint:disable-next-line:no-empty
    ngOnInit() {}

    ionViewWillEnter(): void{
        let result = '';
        for(let i = 0; i < 8; i++){
            result += this.randomChars.charAt(Math.floor(Math.random()*this.randomChars.length));
        }
        this.confirmSequence = result;
    }

    public delete(): void{
        this.modalController.dismiss({
            'delete': true,
        });
    }

}
