import * as ROUTES from "./Routes";
import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { DataService } from "./data.service";
import {GenericResponse} from "../models/GenericResponse.model";
import { Observable } from "rxjs";
import {Setting} from "../models/Setting.model";
import {ToastService} from "./toast.service";

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    public static CAPTCHA_KEY = "captcha.key";
    public static SETTING_WORDPRESS_SHOP_URL = "wordpress.shop.url";
    public static SETTING_EDITOR_DEBUG_MODE = "editor.debug_mode";
    public static SETTING_EDITOR_HELP_URL = "editor.helpurl";
    public recaptchaKey: string = '';
    public captchaKeyEvent: EventEmitter<any> = new EventEmitter<any>();
    public maintenanceEvent: EventEmitter<any> = new EventEmitter<any>();
    public maintenanceMode: boolean = false;

    constructor(
        private http: HttpClient,
        private dataService: DataService,
        private toastService: ToastService
    ) {
        this.loadCaptcha();
        this.loadMaintenance();
        setInterval(() => {
            this.loadMaintenance();
        }, 60000);
    }

    public create(setting: Setting): Observable<GenericResponse<Setting>> {
        return this.http.post<GenericResponse<Setting>>(`${ROUTES.SETTINGS_ROUTE}/`, setting, this.dataService.getHttpOptions());
    }

    public update(setting: Setting): Observable<GenericResponse<Setting>> {
        return this.http.put<GenericResponse<Setting>>(`${ROUTES.SETTINGS_ROUTE}/${setting.key}/`, setting, this.dataService.getHttpOptions());
    }

    public delete(settingKey: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${ROUTES.SETTINGS_ROUTE}/${settingKey}/`, this.dataService.getHttpOptions());
    }

    public get(settingKey: string): Observable<GenericResponse<Setting>> {
        return this.http.get<GenericResponse<Setting>>(`${ROUTES.SETTINGS_ROUTE}/${settingKey}/`, this.dataService.getHttpOptions());
    }

    public getAll(settingCategory: string): Observable<GenericResponse<Setting[]>> {
        return this.http.get<GenericResponse<Setting[]>>(`${ROUTES.SETTINGS_ROUTE}/all/${settingCategory}/`, this.dataService.getHttpOptions());
    }

    public loadCaptcha(): void{

        this.get(SettingsService.CAPTCHA_KEY).toPromise()
            .catch(err => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.toastService.showToast(ToastService.TOAST_STATUS.warning, ToastService.DEFAULT_DURATION,'PAGES.REGISTER.LOADING_CAPTCHA_KEY_FAILED');
            })
            .then((res: GenericResponse<Setting>) => {
                this.recaptchaKey = res.body.value;
                this.captchaKeyEvent.next();
            });
    }

    public loadMaintenance(): void{
        this.get('maintenance.mode').subscribe({
            error: (err: HttpErrorResponse) => {
                // tslint:disable-next-line:no-console
                console.log(err);
                this.maintenanceMode = false;
            },
            next: (response: GenericResponse<Setting>) => {
                if (response.status === 200) {
                    this.maintenanceMode = response.body.value == 'true';
                } else {
                    this.maintenanceMode = false;
                }
                this.maintenanceEvent.next();
            },
        });
    }

}
