import {CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {SettingsService} from "../services/settings.service";


@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuardService implements CanActivate {
    constructor(
        private router: Router,
        private settingsService: SettingsService
    ) { }

    public canActivate(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            observer.next(!this.settingsService.maintenanceMode);
        });
    }
}
