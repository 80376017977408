import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "./guards/auth-guard.service";
import {MaintenanceGuardService} from "./guards/maintenance-guard.service";
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
        canActivate: [MaintenanceGuardService],
    },
    {
        path: 'new-open',
        loadChildren: () => import('./pages/new-open/new-open.module').then( m => m.NewOpenPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'child-list',
        loadChildren: () => import('./pages/child-list/child-list.module').then( m => m.ChildListPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
        canActivate: [MaintenanceGuardService],
    },
    {
        path: 'register-activation/:registrationToken',
        loadChildren: () => import('./pages/register-activation/register-activation.module').then( m => m.RegisterActivationPageModule),
        canActivate: [MaintenanceGuardService],
    },
    {
        path: 'select-design',
        loadChildren: () => import('./pages/select-design/select-design.module').then( m => m.SelectDesignPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'folder-editor',
        loadChildren: () => import('./pages/folder-editor/folder-editor.module').then( m => m.FolderEditorPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
        canActivate: [MaintenanceGuardService],
    },
    {
        path: 'confirm-password-reset/:passwordResetToken',
        loadChildren: () => import('./pages/confirm-password-reset/confirm-password-reset.module').then( m => m.ConfirmPasswordResetPageModule),
        canActivate: [MaintenanceGuardService],
    },
    {
        path: 'profile-editor',
        loadChildren: () => import('./pages/profile-editor/profile-editor.module').then( m => m.ProfileEditorPageModule),
        canActivate: [AuthGuardService],
    },
    {
        path: 'project-list',
        loadChildren: () => import('./pages/project-list/project-list.module').then( m => m.ProjectListPageModule),
        canActivate: [AuthGuardService],
    },


];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
