import * as Routes from "./Routes";
import {EventEmitter, Injectable} from '@angular/core';
import {AuthGuardService} from "../guards/auth-guard.service";
import { DataService } from './data.service';
import {DomSanitizer} from "@angular/platform-browser";
import {GenericResponse} from "../models/GenericResponse.model";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Template} from "../models/Template.model";
import {TemplateArea} from "../models/TemplateArea.model";
import {TemplateColor} from "../models/TemplateColor.model";
import {ToastService} from "./toast.service";

@Injectable({
    providedIn: 'root',
})
export class TemplateService{

    public static AREA_ORIENTATION_TYPES:{key: string, name:string}[] = [{key: 'horizontal', name: 'Horizontal'}, {key: 'vertical', name: 'Vertical'}];
    public static AREA_TYPES:{key: string, name:string}[] = [{key: 'image', name: 'Image'}, {key: 'text', name: 'Text'}];

    public templates: {template: Template, colors: {color: TemplateColor, image: any}[]}[];
    public templateUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

    private lastLoaded: Date;

    constructor(
        private dataService: DataService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private authGuard: AuthGuardService,
        private toastService: ToastService
    ) {
        this.templates = [];
    }

    public create(template: Template): Observable<GenericResponse<Template>> {
        return this.http.post<GenericResponse<Template>>(`${Routes.TEMPLATE_ROUTE}/`, template, this.dataService.getHttpOptions());
    }

    public update(template: Template): Observable<GenericResponse<Template>> {
        return this.http.put<GenericResponse<Template>>(`${Routes.TEMPLATE_ROUTE}/${template.templateId}/`,
            template,this.dataService.getHttpOptions());
    }

    public delete(templateId: string): Observable<GenericResponse<string>> {
        return this.http.delete<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptions());
    }

    public get(templateId: string): Observable<GenericResponse<Template>> {
        return this.http.get<GenericResponse<Template>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/`, this.dataService.getHttpOptions());
    }

    public getAll(onlyActive: boolean = true): Observable<GenericResponse<Template[]>> {
        return this.http.get<GenericResponse<Template[]>>(`${Routes.TEMPLATE_ROUTE}/all/${!onlyActive}`, this.dataService.getHttpOptions());
    }

    // Template Areas:

    public getAllTemplateAreas(templateId: string): Observable<GenericResponse<TemplateArea[]>>{
        return this.http.get<GenericResponse<TemplateArea[]>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/areas/`, this.dataService.getHttpOptions());
    }

    public createTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
        return this.http.post<GenericResponse<TemplateArea>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/`,
            templateArea, this.dataService.getHttpOptions());
    }

    public updateTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<TemplateArea>>{
        return this.http.put<GenericResponse<TemplateArea>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/${templateArea.areaId}/`,
            templateArea, this.dataService.getHttpOptions());
    }

    public deleteTemplateArea(templateArea: TemplateArea): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/${templateArea.templateId}/areas/${templateArea.areaId}/`,
            this.dataService.getHttpOptions());
    }

    // Template Colors:

    public getAllTemplateColors(templateId: string): Observable<GenericResponse<TemplateColor[]>>{
        return this.http.get<GenericResponse<TemplateColor[]>>(`${Routes.TEMPLATE_ROUTE}/${templateId}/colors/`, this.dataService.getHttpOptions());
    }

    public createTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
        return this.http.post<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/`,
            templateColor, this.dataService.getHttpOptions());
    }

    public updateTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<TemplateColor>>{
        return this.http.put<GenericResponse<TemplateColor>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/${templateColor.templateColorId}/`,
            templateColor, this.dataService.getHttpOptions());
    }

    public deleteTemplateColor(templateColor: TemplateColor): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/${templateColor.templateId}/colors/${templateColor.templateColorId}/`,
            this.dataService.getHttpOptions());
    }

    public upload(file): Observable<{ id: string }>{
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<{ id: string }>(`${Routes.TEMPLATE_ROUTE}/upload`, formData, this.dataService.getHttpOptions());
    }

    public getFile(fileId: string): Observable<any>{
        return this.http.get<any>(`${Routes.TEMPLATE_ROUTE}/file/${fileId}`, this.dataService.getHttpOptionsDownload());
    }

    public getFileName(fileId: string): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${Routes.TEMPLATE_ROUTE}/filename/${fileId}`, this.dataService.getHttpOptions());
    }

    public queryTemplate(showError: boolean = true): void{
        if(this.templates == undefined || this.templates.length < 1 || this.lastLoaded == undefined ||
            this.lastLoaded.getTime() > (new Date().getTime() + (5 * 60 * 1000))){
            this.lastLoaded = new Date();
            this.loadTemplates(0).then(() => {this.loadColors(0, showError);});
        }
    }

    private async loadTemplates(tries: number): Promise<void>{
        let retry = false;
        let error = false;
        tries += 1;
        await this.getAll().toPromise().catch(err => {
            // tslint:disable-next-line:no-console
            console.log(err);
            retry = true;
            error = true;
        }).then((value: GenericResponse<Template[]>) => {
            let tmp = [];
            value.body.forEach(t => {
                tmp.push({template: t,colors: []});
            });
            this.templates = tmp.sort((a,b) => a.template.name.localeCompare(b.template.name));
        });
        if(retry && tries <= 5){
            await this.loadTemplates(tries);
        }else{
            if(error){
                this.toastService.showToast(ToastService.TOAST_STATUS.danger,ToastService.DEFAULT_DURATION, 'SERVICES.TEMPLATE.LOADING_TEMPLATES_FAILED');
            }
        }
    }

    private async loadColors(tries: number, showError: boolean = true): Promise<void> {
        let retry = false;
        let colorError = false;
        let fileError = false;
        tries += 1;
        for (let i = 0; i < this.templates.length; i++) {
            await this.getAllTemplateColors(this.templates[i].template.templateId).toPromise().catch(err => {
                colorError = true;
                // tslint:disable-next-line:no-console
                console.log(err);
                retry = true;
            }).then(async (value: GenericResponse<TemplateColor[]>) => {
                if (value.body.length > 0) {
                    let tmp = [];
                    await value.body.forEach(col => {
                        this.getFile(col.thumbnailFileId).toPromise().catch(err => {
                            fileError = true;
                            // tslint:disable-next-line:no-console
                            console.log(err);
                            retry = true;
                        }).then(value1 => {
                            let objectUrl = URL.createObjectURL(value1.body);
                            tmp.push({color: col, image: this.sanitizer.bypassSecurityTrustUrl(objectUrl)});
                        });
                    });
                    this.templates[i].colors = tmp;
                    this.templateUpdateEvent.next();
                }
            });
        }
        if(retry && tries <= 5){
            this.loadColors(tries, showError);
        }else{
            if(colorError){
                this.toastService.showToast(ToastService.TOAST_STATUS.danger, ToastService.DEFAULT_DURATION, 'SERVICES.TEMPLATE.LOADING_TEMPLATE_COLORS_FAILED');
            }
            if(fileError && showError){
                this.toastService.showToast(ToastService.TOAST_STATUS.danger, ToastService.DEFAULT_DURATION, 'SERVICES.TEMPLATE.LOADING_TEMPLATE_COLOR_FILE_FAILED');
            }
        }
    }
}
