import {Component, Input, OnInit} from '@angular/core';
import {Child} from "../../models/Child";
import {ModalController} from "@ionic/angular";
import {ToastService} from "../../services/toast.service";

@Component({
    selector: 'app-child-detail',
    templateUrl: './child-detail.component.html',
    styleUrls: ['./child-detail.component.scss'],
})
export class ChildDetailComponent implements OnInit {

    @Input()
    public isAdd: boolean = false;

    @Input()
    public child: Child;

    constructor(
        public modalController: ModalController,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        if(this.child == undefined){
            this.child = new Child();
        }
    }

    public save(): void{
        if(this.childIsValid()){
            this.modalController.dismiss({
                'child': this.child,
                'isAdd': this.isAdd,
                'delete': false,
            });
        }else{
            this.toastService.showToast('warning', 10000, 'MODALS.ADD_CHILD.MANDATORY_MISSING');
        }
    }

    public delete(): void{
        this.modalController.dismiss({
            'child': this.child,
            'isAdd': this.isAdd,
            'delete': true,
        });
    }

    private stringIsValid(s: string): boolean{
        return s != undefined && s.length > 0;
    }

    public childIsValid(): boolean{
        return this.stringIsValid(this.child.name)
            && this.stringIsValid(this.child.groupName)
            && this.stringIsValid(this.child.startYear)
            && this.stringIsValid(this.child.endYear);
    }

    public close(): void{
        this.modalController.dismiss();
    }
}
