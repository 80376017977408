import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ChildDetailComponent} from "./child-detail/child-detail.component";
import {ColorPickerModule} from "ngx-color-picker";
import {CommonModule} from "@angular/common";
import {DeleteProfileConfirmComponent} from "./delete-profile-confirm/delete-profile-confirm.component";
import {MobileDesignSelectComponent} from "./mobile-design-select/mobile-design-select.component";
import { NgModule } from '@angular/core';
import {NgxFileDropModule} from "ngx-file-drop";
import {TabletEditorMenuComponent} from "./tablet-editor-menu/tablet-editor-menu.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        ChildDetailComponent,
        DeleteProfileConfirmComponent,
        MobileDesignSelectComponent,
        TabletEditorMenuComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxFileDropModule,
        ColorPickerModule,
    ],
})
export class SharedModule {}
