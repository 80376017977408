import * as Routes from './Routes';
import {EventEmitter, Injectable} from '@angular/core';
import { DataService } from './data.service';
import {GenericResponse} from "../models/GenericResponse.model";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Response} from "../models/Response";
import { Router } from '@angular/router';
import {ToastService} from "./toast.service";
import {User} from "../models/User";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public loggedInEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private dataService: DataService,
        private http: HttpClient,
        private router: Router,
        private toastService: ToastService
    ) {}

    public login(email: string, password: string): Observable<Response> {
        return this.http.post<Response>(`${Routes.USER_ROUTE}/login`, {email, password}, this.dataService.getHttpOptions());
    }

    public logout(): void {
        this.dataService.clearToken();
        this.router.navigate(['/login']);
    }

    public checkTokenValidation(): Observable<Response> {
        return this.http.post<Response>(`${Routes.USER_ROUTE}/validateToken`, {}, this.dataService.getHttpOptions());
    }

    public register(user: User): Observable<Response> {
        return this.http.post<Response>(`${Routes.USER_ROUTE}/register/`, user, this.dataService.getHttpOptions());
    }

    public activateViaToken(token: string): Observable<GenericResponse<string>>{
        return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/activate/${token}/`, {}, this.dataService.getHttpOptions());
    }

    public resetPassword(email: string): Observable<GenericResponse<string>> {
        return this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/resetPassword/`, {email}, this.dataService.getHttpOptions());
    }

    public resetPasswordConfirm(password: string, token: string): Observable<GenericResponse<string>> {
        return this.http.post<GenericResponse<string>>(
            `${Routes.USER_ROUTE}/resetPassword/confirm/`,
            {passwordResetToken: token, newPassword:password},
            this.dataService.getHttpOptions()
        );
    }

    public async refreshToken(): Promise<boolean>{
        let success = false;
        await this.http.post<GenericResponse<string>>(`${Routes.USER_ROUTE}/refreshToken`, {}, this.dataService.getHttpOptions()).toPromise()
            .catch(() => {
                this.toastService.showToast(ToastService.TOAST_STATUS.warning,ToastService.DEFAULT_DURATION, 'TOASTS.TOKEN_REFRESH_FAILED');
            })
            .then((res: GenericResponse<string>) => {
                if(res.token != undefined && res.token.length > 0){
                    this.dataService.setToken(res.token);
                    success = true;
                }
            });
        return success;
    }

    public loginAsTempUser(): Observable<GenericResponse<string>> {
        return this.http.post<GenericResponse<string>>(
            `${Routes.USER_ROUTE}/login/temp`,
            {},
            this.dataService.getHttpOptions()
        );
    }

    public registerTempUser(user: User): Observable<Response> {
        return this.http.post<Response>(`${Routes.USER_ROUTE}/register/temp`, user, this.dataService.getHttpOptions());
    }
}
