import * as Routes from "./Routes";
import {DataService} from "./data.service";
import {GenericResponse} from "../models/GenericResponse.model";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Project} from "../models/Project.model";
import {ProjectItem} from "../models/ProjectItem.model";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    public static TOAST_STATUS = {
        danger: 'danger',
        warning: 'warning',
        success: 'success',
    };
    public static DEFAULT_DURATION = 5000;

    constructor(
        private translate: TranslateService
    ) { }

    public showToast(toastStatus: string, toastDurationInMs: number, toastMessageTranslateKey: string): void{
        if(document.getElementById('porttoolioToast').getAttribute('data-status') == 'hidden'){

            this.translate.get(toastMessageTranslateKey).toPromise()
                .then((res: string) => {
                    if(toastDurationInMs < 500){
                        toastDurationInMs = 500;
                    }
                    document.getElementById('porttoolioToastContent').innerText = res;
                    document.getElementById('porttoolioToast').setAttribute('data-status', toastStatus);
                    setTimeout(() => {
                        document.getElementById('porttoolioToast').setAttribute('data-status', 'hidden');
                    },toastDurationInMs);

                });
        }else{
            setTimeout(() => {
                this.showToast(toastStatus, toastDurationInMs, toastMessageTranslateKey);
            }, 1000);
        }
    }
}
