import * as Routes from "./Routes";
import {DataService} from "./data.service";
import {GenericResponse} from "../models/GenericResponse.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {User} from "../models/User";

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private dataService: DataService,
        private http: HttpClient
    ) {}

    public getSelf(): Observable<GenericResponse<User>> {
        return this.http.get<GenericResponse<User>>(`${Routes.USER_ROUTE}/`, this.dataService.getHttpOptions());
    }

    public update(user: User): Observable<GenericResponse<User>>{
        return this.http.put<GenericResponse<User>>(`${Routes.USER_ROUTE}/${user.userId}/`, user, this.dataService.getHttpOptions());
    }

    public getAuthKey(): Observable<GenericResponse<string>>{
        return this.http.get<GenericResponse<string>>(`${Routes.USER_ROUTE}/authKey/`, this.dataService.getHttpOptions());
    }

    public delete(user: User): Observable<GenericResponse<string>>{
        return this.http.delete<GenericResponse<string>>(`${Routes.USER_ROUTE}/${user.userId}/`, this.dataService.getHttpOptions());
    }

}
